<template>
  <div class="register-container">
    <h2>User regitrarion form Pheflux</h2>
    <form @submit.prevent="submitForm">
      <div class="form-group">
        <label for="username">Username: <span class="required-symbol">👁️</span></label>
        <input id="username" v-model="formData.username" class="form-control" type="text" placeholder="Enter the username you will use" required />
      </div>
      <div class="form-group">
      <label for="name">Name: <span class="required-symbol">👁️</span></label>
        <input id="name" v-model="formData.name" class="form-control" type="text" placeholder="Enter your name" required />
      </div>
      <div class="form-group">
        <label for="last_name">Last name: <span class="required-symbol">👁️</span></label>
        <input id="last_name" v-model="formData.last_name" class="form-control" type="text" placeholder="Enter your last name" required />
      </div>
      <div class="form-group">
        <label for="email">Email: <span class="required-symbol">👁️</span></label>
        <input id="email" v-model="formData.email" class="form-control" type="email" placeholder="Enter your email" required />
      </div>
      <div class="form-group">
        <label for="password">Password: <span class="required-symbol">👁️</span></label>
        <input id="password" v-model="formData.password" class="form-control" type="password" placeholder="Enter the password you will use" required />
      </div>
      <div class="form-group">
        <label for="phone_number">phone number:</label>
        <input id="phone_number" v-model="formData.phone_number" class="form-control" type="text" placeholder="Enter your phone number" />
      </div>
      <div class="form-group terms-group">
        <label>
          <input type="checkbox" id="terms" v-model="formData.terms" required />
          I agree to the <a @click="showTerms" href="#">terms and conditions of use</a>.
        </label>
      </div>
      <button type="submit" :disabled="!formData.terms || !isValidForm" class="register-button">Register</button>
      <div v-if="error" class="error-message">{{ error }}</div>
      <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
    </form>

    <!-- Modal for Terms and Conditions -->
    <div v-if="showTermsModal" class="modal-overlay">
      <div class="modal">
        <h3>Terms and conditions</h3>
        <Terms />
        <button @click="closeTerms" class="modal-close-button">Close</button>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref } from 'vue';
import Terms from './Terms.vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useConfigStore } from '@stores/configStore';

interface FormData {
  username: string;
  name: string,
  last_name: string;
  email: string;
  password: string;
  phone_number: string | number;
  terms: boolean;
}

export default defineComponent({
  components: {
    Terms,
  },
  setup() {
    const configStore = useConfigStore();
    const api = axios.create({
      baseURL: configStore.baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
      });

    const formData = reactive<FormData>({
      username: '',
      name: '',
      last_name: '',
      password: '',
      email: '',
      phone_number: '',
      terms: false,
    });

    const error = ref('');
    const successMessage = ref('');
    const showTermsModal = ref(false);

    const requiredFields = ['name', 'username', 'last_name', 'email', 'password']; 

    const isValidForm = () => {
      const formDataAsIndexed = formData as { [key: string]: string | boolean | number };
      const missingFields = requiredFields.filter(
        (field) => !formDataAsIndexed.hasOwnProperty(field) || !formDataAsIndexed[field]
      );
      if (missingFields.length > 0) {
        error.value = `Por favor, completa los siguientes campos obligatorios: ${missingFields.join(', ')}.`;
        return false;
      }
      return true;
    };

    const submitForm = async () => { // Use async/await for cleaner handling
      if (!isValidForm()) return;

      try {
        const response = await api.post('/api/register', formData); // Replace '/register' with your actual endpoint
        console.log('Registro enviado con éxito:', response.data);
        // Handle successful response, e.g., show success message, redirect
        successMessage.value = '¡Tu registro fue enviado con éxito!';
        error.value = '';
        setTimeout(() => {
          successMessage.value = '';
        }, 3000);
      } catch (error) {
        console.error('Error al enviar el registro:', error);
        // Handle error, e.g., show error message
        error.value = 'Ocurrió un error al enviar el registro. Por favor, inténtalo de nuevo.';
      } finally {
        // Optional cleanup actions (always executes)
      }
    };

    const showTerms = () => {
      showTermsModal.value = true;
    };

    const closeTerms = () => {
      showTermsModal.value = false;
    };

    return {
      formData,
      submitForm,
      isValidForm,
      error,
      successMessage,
      showTermsModal,
      showTerms,
      closeTerms,
    };
  },
});
</script>

<style scoped>
.register-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

h2 {
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.form-control:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.required-symbol {
  color: red;
  font-size: 1.2em;
  margin-left: 5px;
}

.terms-group {
  display: flex;
  align-items: center;
}

.register-button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 16px;
}

.success-message {
  color: green;
  margin-top: 16px;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
}

.modal h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

.modal-close-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal-close-button:hover {
  background-color: #0056b3;
}
</style>