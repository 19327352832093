<template>
  <div class="organism-list-page">
    <div class="search-organisms">
      <label><font-awesome-icon icon="fa-magnifying-glass" /> </label>
      <input
        class="search-input"
        type="text"
        placeholder="Search Organisms here..."
        v-model="searchTerm"
      />
      <!--<button @click="searchOrganisms">Search</button>-->
    </div>
    <h2>
      <font-awesome-icon icon="fa-dna" />
      Organism List ({{ filteredOrganisms.length }} found)
    </h2>
    <button @click="goToCreatePage" class="create-button">
      <font-awesome-icon icon="folder-plus" />
      New Organism
    </button>
    <table class="organism-table">
      <thead>
        <tr>
          <th>ID</th>
          <th>Organism Name</th>
          <th>Condition</th>
          <th>Date Created</th>
          <th>Valid</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="loading">
          <td colspan="7">Loading...</td>
        </tr>
        <tr v-else-if="!filteredOrganisms.length">
          <td colspan="7">No organisms found.</td>
        </tr>
        <tr v-else v-for="organism in paginatedOrganisms" :key="organism.id" class="organism-row">
          <td>{{ organism.id }}</td>
          <td>{{ organism.organism }}</td>
          <td>{{ organism.condition }}</td>
          <td>{{ organism.date_creation }}</td>
          <td class="organism-valid">
      <font-awesome-icon
        :icon="organism.valid ? 'fa-check-circle' : 'fa-times-circle'"
        :class="{ 'success-icon': organism.valid, 'no-valid': !organism.valid }"
        :title="organism.valid ? 'Valid Organism' : 'Invalid organism'"
      />
    </td>
          <td>
            <button :title="`Edit organism`" @click="editOrganism(organism.id)" class="style-actions">
              <font-awesome-icon icon="fa-edit" class="text-blue" />
            </button> |
            <button :title="`Delete organism`" @click="deleteOrganism(organism.id)" class="style-actions">
              <font-awesome-icon icon="fa-trash" class="text-danger" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="pagination">
      <button @click="prevPage" :disabled="currentPage === 1" class="pagination-button">❮</button>
      <span class="pagination-current">{{ currentPage }}</span>
      <button @click="nextPage" :disabled="currentPage === totalPages" class="pagination-button">❯</button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import { useAuthStore } from '@stores/authStore';
import { useConfigStore } from '@stores/configStore';

console.log("AuthSTORE->")
const configStore = useConfigStore();
const authStore = useAuthStore();
const api = axios.create({
baseURL: configStore.baseUrl,
headers: {
  'Content-Type': 'application/json',
},
});

console.log("URL",configStore.baseUrl)


interface User {
  username: string;
}

interface Organism {
  id: number;
  organism: string;
  condition: string;
  date_creation: string;
  valid: boolean;
  user: User;
}

const organisms = ref<Organism[]>([]);
const loading = ref(false);
const currentPage = ref(1);
const pageSize = 5; // Set page size
const totalPages = computed(() => Math.ceil(organisms.value.length / pageSize));

const paginatedOrganisms = computed(() => {
  const startIndex = (currentPage.value - 1) * pageSize;
  return filteredOrganisms.value.slice(startIndex, startIndex + pageSize);
});

const router = useRouter();

// Set the Authorization header for all requests if token is available
api.interceptors.request.use((config) => {
  const token = authStore.token;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

async function fetchOrganisms() {
  console.log("INICIANDO FETCH DE ORGANISMS")
  loading.value = true;
  try {
    console.log("LLAMADA A ENDPOINT ORGANISMS/LIST", api)
    const response = await api.get('/api/organisms/organisms');
    organisms.value = response.data;
    console.log("RESULTADOS",(response))

  } catch (error) {
    console.error('Error fetching organisms:', error);
    alert('There was an error fetching the organisms.');
  } finally {
    loading.value = false;
  }
}

function prevPage() {
  if (currentPage.value > 1) {
    currentPage.value--;
  }
}

function nextPage() {
  if (currentPage.value < totalPages.value) {
    currentPage.value++;
  }
}

function editOrganism(id: number) {
  console.log(`Edit organism with ID: ${id}`);
}

//------ DELETE
function deleteOrganism(id: number) {
  const organismToDelete = organisms.value.find(organism => organism.id === id);
  if (organismToDelete) {
    const confirmed = confirm(`¿Are you sure you want to eliminate the organism "${organismToDelete.organism}"?`);
    if (confirmed) {
      organisms.value = organisms.value.filter((organism) => organism.id !== id);
      // Mostrar un mensaje de éxito usando un componente de notificación o una alerta
      console.log(`Organismo "${organismToDelete.organism}" Removed successfully.`);
    }
  }
}
//------

function goToCreatePage() {
  router.push({ name: 'CreateOrganism' });
}

//......... BÚSQUEDA DE ORGANISMOS
const searchTerm = ref('');

// Create a computed property to filter organisms based on searchTerm
const filteredOrganisms = computed(() => {
  // Filtrado basado en el término de búsqueda 
  const searchLower = searchTerm.value.toLowerCase();
  const filtered = organisms.value.filter(organism => {
    const organismLower = (organism.organism + ' ' + organism.condition).toLowerCase();
    return organismLower.includes(searchLower);
  });

  // Ordenamiento por fecha de creación (más reciente primero)
  return filtered.sort((a, b) => {
    // Convertir fechas a objetos Date para una comparación precisa
    const dateA = new Date(a.date_creation);
    const dateB = new Date(b.date_creation);

    // Ordenar de manera descendente (más reciente primero)
    return dateB.getTime() - dateA.getTime();
  });
});

// Update organism search results when searchTerm changes
watch(searchTerm, () => {
  currentPage.value = 1; // Reset pagination on search
});

// Search organisms based on searchTerm
async function searchOrganisms() {
  if (!searchTerm.value.trim()) {
    // If search term is empty, fetch all organisms again
    await fetchOrganisms();
  }
}
//.........

// Fetch organisms when the component is mounted
onMounted(() => {
  fetchOrganisms();
});
</script>

<style scoped>
.search-organisms {
  display: flex;
  align-items: center;
}

.search-organisms label {
  margin-right: 10px;
}

.search-input {
  width: 30%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  box-sizing: border-box;
}

.search-input:focus {
border-color: #007bff;
outline: none;
box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.organism-list-page {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }


h2 {
  color: #333;
}

.create-button {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.create-button:hover {
  background-color: #0056b3;
}

.organism-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.success-icon {
  color: green; /* Adjust color as needed */
  cursor: pointer;
}

.no-valid {
  color: red;
  cursor: pointer;
}

.organism-table th,
.organism-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
  font-size: 16px;
}


.organism-table th {
  background-color: #f2f2f2;
  color: #333;
}


.organism-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.organism-table tr:hover {
  background-color: #f1f1f1;
}

.action-link {
  color: #3498db;
  text-decoration: none;
  cursor: pointer;
}

.action-link:hover {
  text-decoration: underline;
}

.style-actions {
  background-color: transparent;
  border: none;
  appearance: none;
  padding: 10px 20px;
  cursor: pointer;
}

.text-blue {
  color: #555555;
  cursor: pointer;
  transition: transform 0.1s ease; /* Add transition effect */
}

.text-blue:hover {
  color: #000000;
  transform: scale(1.1); /* Zoom in by 10% */
}

.text-danger {
  color: #555555;
  cursor: pointer;
  transition: transform 0.1s ease; /* Add transition effect */
}

.text-danger:hover {
  color: #000000;
  transform: scale(1.1); /* Zoom in by 10% */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}

.pagination-button {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  font-size: 16px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-current {
  font-size: 16px;
  font-weight: bold;
}
</style>

